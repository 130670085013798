@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: url(webfonts/Open_Sans-italic-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: url(webfonts/Open_Sans-italic-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: url(webfonts/Open_Sans-italic-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: auto;
  src: url(webfonts/Open_Sans-italic-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-display: auto;
  src: url(webfonts/Open_Sans-italic-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(webfonts/Open_Sans-normal-300.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(webfonts/Open_Sans-normal-400.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url(webfonts/Open_Sans-normal-600.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(webfonts/Open_Sans-normal-700.woff) format("woff");
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: auto;
  src: url(webfonts/Open_Sans-normal-800.woff) format("woff");
  unicode-range: U+0-10FFFF;
}
